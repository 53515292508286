import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function Page() {
  return (
    <Layout>
      <SEO title="About" />

      <div className="container mx-auto px-6 py-2 flex justify-between items-center">
        <section className="flex flex-col items-center md:flex-row">
          <div className="md:w-2/3 md:mr-8">
            <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
              The point is... to live one&apos;s life in the full complexity of
              what one is, which is something much darker, more contradictory,
              more of a maelstrom of impulses and passions, of cruelty, ecstacy,
              and madness, than is apparent to the civilized being who glides on
              the surface and fits smoothly into the world.
            </blockquote>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Page;
